.org-tree {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.org-level {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.org-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.org-node {
  width: 200px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

}

.ant-card .org-node .ant-card-body {
  padding: 0;
}

.org-node-content {
  h4, p {
  margin: 2px;
    }
}
