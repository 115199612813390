.release-log-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.release-log-item {
  font-size: 16px;
  margin-bottom: 10px;

  .upcoming-release {
    color: red;
    font-weight: bold;
    margin-left: 10px;
  }
}
