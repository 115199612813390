.recruitment-manager {
  padding: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
