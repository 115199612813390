.rubrics-manager-card {
  margin: 0 auto;
}

.rubrics-list-container {
  margin-bottom: 20px;
}

.rubric-actions {
  text-align: right;
}

.limited-width {
  max-width: 500px;
}
