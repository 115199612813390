.audio-container {
  margin: 0 auto;

  .card {
    margin-bottom: 20px;
  }

  .summary-card,
  .transcript-card {
    .ant-card-head {
      background-color: #f0f2f5;
    }
  }

  .card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .transcription {
    max-height: 300px;
    overflow-y: auto;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .ant-card .ant-card-body {
    max-width: 1200px;
  }

}

.ant-upload-drag {
  p.ant-upload-drag-icon {
    margin-bottom: 20px;

    .anticon {
      font-size: 48px;
      color: #1890ff;
    }
  }

  p.ant-upload-text {
    font-size: 16px;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transcript-card .transcription {
  max-height: 400px; /* Adjust based on your layout */
  padding: 16px;
  background-color: #ffffff; /* Add background for better readability */
  border: 0; /* Add border to distinguish markdown container */
  border-radius: 4px; /* Add some rounding to the edges */
  white-space: pre-wrap; /* Ensure newlines and spaces are respected */
  word-wrap: break-word; /* Break long words and wrap text to prevent horizontal scrolling */
  overflow-wrap: break-word; /* Ensures long words break to prevent overflow */
}

.transcript-card .transcription h1,
.transcript-card .transcription h2,
.transcript-card .transcription h3,
.transcript-card .transcription h4,
.transcript-card .transcription h5,
.transcript-card .transcription h6 {
  margin-top: 16px;
  margin-bottom: 8px;
}

.transcript-card .transcription p {
  margin: 8px 0;
}

.transcript-card .transcription ul,
.transcript-card .transcription ol {
  padding-left: 20px;
}

.transcript-card .transcription code {
  background-color: #ffffff;
  padding: 2px 4px;
  border-radius: 4px;
}
