/* Variables */
$logo-description-font-size: 14px;
$logo-description-font-weight: 600;
$logo-image-margin-right: 10px;
$header-height: 70px;
$sider-width: 50px;
$white-color: #ffffff;
$black-color: #000000;
$light-gray-color: #f9f9f9;
$gray-color: #f1f1f1;
$light-bg-color: #f8f8fb;

/* General Layout */
.layout {
  min-height: 100vh;
  background-color: $white-color;
  margin: auto; // Centers the layout if screen width is more than 1280px
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white-color;
  height: $header-height;
  border-bottom: 1px solid #ccc;

  h5.ant-typography {
    margin-bottom: 0;
  }

  .logo-wrapper {
    .logo {
      display: flex;
      align-items: center;

      .logo-description {
        vertical-align: middle;
        font-size: $logo-description-font-size;
        font-weight: $logo-description-font-weight;
      }

      .logo-image {
        width: 160px;
      }
    }
  }

  .profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    margin-right: 5px;
  }

  .profile-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.divider-left,
.divider-right {
  border-left: 1px solid $black-color;
  height: 24px;
  margin: 0 10px;
}

.title {
  color: $black-color;
}

.button {
  color: $black-color;
  border-color: $white-color;
  text-align: center;

  &:hover,
  &:focus {
    color: #1890ff;
    border-color: #1890ff;
    background: $white-color;
  }
}

.content {
  padding: 2em;
  min-height: 280px;
  background: $light-bg-color;
}

.footer {
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 220px;
  font-size: 12px;
}

.ant-layout-footer {
  color: rgba(0, 0, 0, 0.88);
  background: $white-color;
}

.ant-layout, .ant-layout .ant-layout-sider {
  background: $white-color;
}

.custom-header {
  background-color: $white-color;
  min-height: $header-height;
  border-bottom: 1px solid #ccc;
}

.review-container {
  margin: 20px;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: #f0f2f5;
    font-size: 18px;
  }

  .ant-card {
    border-radius: 8px;

    p {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .ant-tag {
      margin-bottom: 8px;
      cursor: pointer;

      span {
        cursor: text;
      }
    }

    .review {
      margin: 10px 0;
    }

    .innerText {
      margin: 5px 0;
    }
  }
}

.job-role-select,
.skills-select {
  width: 220px;
  margin: 20px 10px;
}

.reset-filter-btn {
  margin: 0 20px;
}

.outer-data-table {
  margin: 20px 0;

  .ant-table-thead > tr > th {
    background: $white-color;
    color: $black-color; // optional, specifies the color of the text
  }
}

.card {
  &.transcript-card,
  &.summary-card {
    margin-top: 16px;
  }
}

.card-header {
  width: 100%;

  .ant-btn {
    color: rgba(0, 0, 0, 0.45);
    padding: 0;
  }

  .ant-btn:hover {
    color: #1890ff;
  }
}

.reset-btn {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.select-max-width {
  max-width: 500px;
}

.group-header-row {
  background-color: #f0f0f0;
  font-weight: bold;
}

.skill-comparison-container {
  padding: 20px;

  .ant-table {
    margin: 0;
  }

  .ant-table td {
    border-right: 1px solid #e8e8e8;

    &:last-child {
      border-right: none;
    }
  }
}

.orange {
  background-color: orange;
}

.green {
  background-color: green;
}

.skill-gap-visualization {
  .skill-bar {
    margin-bottom: 10px;

    .progress-bar-outer {
      height: 20px;
      width: 100%;
      background-color: #f0f2f5;
      border-radius: 10px;
      overflow: hidden;
    }

    .progress-bar-inner {
      height: 100%;
      display: inline-block;
    }

    .progress-bar-inner.desired {
      background-color: #52c41a; // Green for the desired proficiency
    }

    .proficiency-levels {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
  }

  .career-development-plan {
    margin-top: 20px;

    .ant-list-item {
      padding-left: 20px;
      border-left: 3px solid #52c41a; // Color it to match your design
    }
  }
}

.row-even {
  background-color: $light-gray-color; // Light gray for even rows
}

.row-odd {
  background-color: $white-color; // White for odd rows
}

.inner-row-even {
  background-color: $gray-color; // Slightly darker gray for even inner rows
}

.inner-row-odd {
  background-color: #fafafa; // Slightly lighter gray for odd inner rows
}

.ant-select.ant-select-in-form-item {
  width: 500px;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px; // Added gap for spacing between buttons
}

.framework-tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.framework-tile,
.add-framework-tile {
  width: 150px;
  height: 150px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-framework-tile {
  border: 1px dashed #d9d9d9;
}

.not-found-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found-container {
  text-align: center;
  padding: 20px;
}

.not-found-icon {
  font-size: 64px;
  color: #ff4d4f;
}

.not-found-title {
  font-size: 24px;
  margin-top: 20px;
}

.not-found-description {
  font-size: 16px;
  color: #888;
}

.list-assessment-container {
  .sector-select,
  .job-role-select,
  .skills-select {
    margin-right: 8px;
    width: 200px;
  }

  .reset-filter-btn,
  .export-btn {
    margin-right: 8px;
  }

  .outer-data-table {
    .job-role-column,
    .skills-column,
    .question-text-column,
    .choices-column,
    .explanation-column,
    .complexity-column {
      max-width: 100px;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }

    .questions-column {
      max-width: 500px;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }

    .job-role-column {
      max-width: 100px;
      word-wrap: break-word;
      white-space: normal;
    }

    .choices-column {
      max-width: 200px;
      word-wrap: break-word;
      white-space: normal;
    }

    .explanation-column {
      max-width: 200px;
      word-wrap: break-word;
      white-space: normal;
    }

    .complexity-column {
      max-width: 50px;
      word-wrap: break-word;
      white-space: normal;
    }
  }
}


.employee-uploader {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.upload-button {
  margin-top: 16px;
}

.card-margin-bottom {
  margin-bottom: 20px;
}

.profiles-container {
  padding: 20px;
  background-color: #fff;
}

@import 'InteractiveAgent.scss';
@import 'Transcribe.scss';
@import 'Organisation.scss';
