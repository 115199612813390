// App.scss
.interactive-agent-layout {
  height: 80vh;
}

.interactive-agent-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.navigation-buttons {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 10;
  pointer-events: none;

  .navigation-button {
    pointer-events: auto;
  }
}

.interactive-agent-card {
  height: 100%;
  overflow-y: auto;

  .ant-card-body {
    height: calc(100% - 55px); // Adjust if needed based on your card header height
    padding: 24px 50px;
  }
}

.default-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  max-width: 600px;
  margin: 0 auto;

  .logo {
    width: 120px;
    margin-bottom: 20px;
  }
}

.message-input-container {
  padding: 10px;
}

.markdown-reset {
  img {
    display: block;
    max-width: 50%;
    height: auto;
    margin: 0 auto;
    transition: max-width 0.3s ease-in-out;

    &.expanded {
      max-width: 100%;
    }
  }

  padding-bottom: 20px;
}

.image-container {
  position: relative;
  text-align: center;
  margin-bottom: 20px;

  .content-image {
    max-width: 40%;
    height: auto;
    transition: max-width 0.3s ease-in-out;

    &.expanded {
      max-width: 100%;
    }
  }

  .image-expand-button {
    position: absolute;
    top: 10px;
    right: 25%; // Adjust based on image width
    transition: right 0.3s ease-in-out;
  }

  &.expanded {
    .image-expand-button {
      right: 10px;
    }
  }
}

.connection-status {
  margin-top: 10px;
  text-align: right;

  .status-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; // Adjust this value as needed
}


.interactive-agent-layout.widget-mode {
  .interactive-agent-content {
    padding: 10px;
  }

  .card-container {
    max-height: 300px;
    overflow-y: auto;
  }

  .message-input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
  }
}
